/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'


const StyledMain = styled.main`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
  outline: none;
  overflow: hidden;
`

const StyledMainContainer = ({ children, ...props }) => (
  <StyledMain
    id="main"
    tabIndex="-1"
    minHeight='calc(100vh - 128px)'
    {...props}
  >
    {children}
  </StyledMain>
)

export const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
`


export { StyledMainContainer }
