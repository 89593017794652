import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
  background,
} from 'styled-system'
import { isFeatureEnabled, featureFlags } from '../../../featureflags'


const StyledContainerNoMotion = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${background}
`

const StyledContainerMotion = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  #blob {
    border: 1px solid red;
  }

  #dot2 {
    color: aqua;
  }

  .mydiv {
    width: 100%;
    height: 100%;
    color: black;
    font-weight: bold;
    animation: myanimation 10s infinite;
  }

  .animated-blob {
    animation: move 10s ease-in-out infinite;
    transform-origin: 50% 50%;
  }

  @keyframes myanimation {
    0% {
      background-color: red;
    }
    25% {
      background-color: yellow;
    }
    50% {
      background-color: green;
    }
    75% {
      background-color: brown;
    }
    100% {
      background-color: red;
    }
  }

  @keyframes move {
    0% {
      transform: scale(1) translate(10px, -30px);
    }
    38% {
      transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
    }
    40% {
      transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
    }
    78% {
      transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
    }
    80% {
      transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
    }
    100% {
      transform: scale(1) translate(10px, -30px);
    }
  }
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${background}
`


const StyledContainer = isFeatureEnabled(featureFlags.MOTION_BG) ? StyledContainerMotion : StyledContainerNoMotion
export default StyledContainer
