import React from 'react'
import PropTypes from 'prop-types'
import StyledContainer from './styles'
import { withPrefix } from 'gatsby'
import { isFeatureEnabled, featureFlags } from '../../../featureflags'


const ParallaxBackgroundOld = props => {
  const getSanitizedUrl = url => `url('${withPrefix(url)}')`

  return (
    <StyledContainer
      position="fixed"
      zIndex={-1}
      width="100vw"
      height="100vh"
      backgroundImage={[
        getSanitizedUrl('images/bg-mvp-2-mobile.webp'),
        getSanitizedUrl('images/bg-mvp-2-tablet.webp'),
        getSanitizedUrl('images/bg-mvp-2-desktop.webp'),
      ]}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      top={0}
      left={0}
      bottom={0}
      right={0}
      {...props}
    />
  )
}


const meshSVG = (
  <svg width="1920px" height="1080px">
    <defs>
      <filter id="blurMe">
        <feGaussianBlur
          in="SourceGraphic"
          stdDeviation="96"
          colorInterpolationFilters="sRGB"
        />
      </filter>
      <filter id="noise">
        <feTurbulence baseFrequency="0.48" xresult="colorNoise" />
        <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
        <feBlend in="SourceGraphic" in2="monoNoise" mode="multiply" />
      </filter>
      <filter id="goo">
        <feGaussianBlur
          in="SourceGraphic"
          stdDeviation="4"
          colorInterpolationFilters="sRGB"
        />
      </filter>
    </defs>

    <g filter="url(#noise)">
      <g filter="url(#blurMe)" fillOpacity="0.4">
        {/* light blue */}
        <g className="dot4 " filter="url(#goo)">
          <path
            fill="#38AEEB"
            width="56"
            d="M539.8 137.6c98.3 69 183.5 56
             203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 72.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"
            transform="translate(600,200)"
          ></path>
          <circle fill="#38AEEB" cx="700" cy="250" r="180" />
        </g>
        {/* purple */}
        <g className="dot3 animated-blob" filter="url(#goo)">
          <circle fill="#8110FF" cx="200" cy="200" r="180" />
          <circle fill="#8110FF" cx="300" cy="300" r="280" />
        </g>
        {/* yellow */}
        <g id="dot2 animated-blob" filter="url(#goo)">
          <path
            fill="currentColor"
            width="333"
            d="M539.8 137.6c98.3 69 183.5 124 203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 144.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"
            transform="translate(50,768)"
          ></path>
        </g>
        {/* dark blue */}
        <g className="dot1 animated-blob" filter="url(#goo)">
          <path
            fill="#fffc00"
            width="333"
            d="M539.8 137.6c98.3 69 183.5 124 203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 144.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"
            transform="translate(50,768)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

const ParallaxBackground = props => {

  return (
    <StyledContainer
      position="fixed"
      zIndex={-1}
      width="100vw"
      height="100vh"
      top={0}
      left={0}
      bottom={0}
      right={0}
      {...props}
    >
      {meshSVG}
      </StyledContainer>
  )
}


ParallaxBackground.propTypes = {}

const PB = isFeatureEnabled(featureFlags.MOTION_BG) ? ParallaxBackground : ParallaxBackgroundOld
export default PB