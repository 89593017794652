import { css, createGlobalStyle } from 'styled-components'
import theme from '@themes'

export const fontStack = css`
  font-family: ${p => theme.fonts.body};
  font-weight: 400;
  font-style: normal;
`

export const GlobalStyle = createGlobalStyle`

  html {
    ${fontStack};
    width: 100%; 
    height: 100%;
  }

  body, header, footer {
    overscroll-behavior-y: contain;
    color: ${p => theme.colors.text.primary};
    transition: background 0.2s linear, fill 0.3s linear, opacity 0.3s linear;
    background-color: ${p => theme.colors.bg.primary};
    border-color: ${p => theme.colors.border.primary};
    line-height: 1.2;
    text-shadow: 0px 0px 24px rgba(3, 6, 66, 0.4);
    margin: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  #___gatsby {
    display: flex;
    justify-content: center;
  }

  h1 {
    line-height: 56px;
  }

  p {
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
  
  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    text-align: inherit;

    background: transparent;

    color: inherit;
    font: inherit;

    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
  }
    

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  textarea {
    ${fontStack};
  }

  .adotbug {
    cursor: pointer;
    opacity: .8;

    :hover {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
  }
`
