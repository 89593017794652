const breakpoints = ['375px', '590px', '800px', '1440px', '1920px']
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

breakpoints.mobileSmall = breakpoints[0]
breakpoints.mobile = breakpoints[1]
breakpoints.tablet = breakpoints[2]
breakpoints.desktop = breakpoints[3]
breakpoints.desktopPlus = breakpoints[4]



const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const shadows = {
  text: '0px 2px 8px #0000004f',
  bg: '2px 2px 2px #000',
}

const zIndices = {
  modal: 200,
  navigation: 100,
}

// Text
const lineHeights = [1, 3, 1.6, 1.6, 1.2, 1.1, 1.2, 1.2] // one up from font size
const fontSizes = [12, 14, 18, 20, 24, 32, 40, 48, 56, 64, 72, 88, 96, 112, 120]
const fonts = {
  body:
    "'museo-sans-display', 'museo-sans', 'Helvetica', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
}
const fontWeights = {
  light: 100,
  regular: 300,
  semiBold: 400,
  bold: 600,
} // follow up later

// Primary Colors
const darkBluePrimary = '#030642' 
const black = '#0D0D0D'
const white = '#FFFFFF'

// Accent Colors
const darkBlue = '#0440D4'
const lightBlue = '#38AEEB'
const darkPurple = '#8110FF'
const lightPurple = '#C451FF'
const darkRed = '#CB0856'
const lightRed = '#F61F4D'
const darkOrange = '#FF4A11'
const lightOrange = '#FF9834'

// Site Specific
const lightStroke = '#1D5A96'

const DefaultTheme = {
  colors: {
    darkBluePrimary,
    black,
    white,
    darkBlue,
    lightBlue,
    darkPurple,
    lightPurple,
    darkRed,
    lightRed,
    darkOrange,
    lightOrange,
    lightStroke,
    bg: {
      primary: darkBluePrimary,
    },
    text: {
      primary: lightBlue,
    },
    border: {
      primary: lightStroke,
    },
  },
  breakpoints,
  fontSizes,
  fontWeights,
  fonts,
  lineHeights,
  shadows,
  space,
  zIndices,
}

export const transitionDuration = {
  default: '0.25s',
}

export default DefaultTheme
