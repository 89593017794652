/* eslint-disable camelcase */
import { useStaticQuery, graphql } from 'gatsby'
import React, { useContext } from 'react'
import { LocaleContext } from '../context'

export const useLocale = () => {
  return useContext(LocaleContext)
}

// take care of double /en-us/en-us/alola
export const LocaleProvider = ({ children, locale }) => {
  let ogLocaleSlug = locale
  let localeSlug = locale.toLowerCase()

  let { locales } = useStaticQuery(graphql`
    query LocalesQuery {
      locales: allContentfulLocale {
        nodes {
          ...locale
        }
      }
    }
  `)
  let localeNodes = locales.nodes.map(({ code, ...rest }) => ({
    lowerCaseCode: code.toLowerCase(),
    code,
    ...rest,
  }))
  const defaultLocale = locales?.nodes?.find(locale => locale.default)
  locales = locales.nodes.map(({ code }) => code.toLowerCase())

  const startsWithLocale = slug => {
    if (!localeNodes) return false

    let flag = false

    localeNodes.forEach(({ code, lowerCaseCode }) => {
      if (
        slug.startsWith(lowerCaseCode) ||
        slug.startsWith(`/${lowerCaseCode}`)
      )
        flag = true
    })
    return flag
  }

  const getLocalizedSlug = slug => {
    if (!slug) return slug
    if (slug === 'en-US' || (slug === '/' && locale === 'en-US')) return '/'
    if (slug === '/' && localeSlug) return localeSlug
    if (startsWithLocale(slug)) return slug
    return localeSlug ? `${localeSlug}/${slug}` : slug
  }

  let currentSlug, currentQuery, currentPath

  if (typeof window !== 'undefined') {
    currentSlug = window?.location?.pathname.slice(1)
    currentQuery = window?.location?.search
    currentPath = decodeURI(`${currentSlug}${currentQuery}`)
  }

  const isActiveSlug = slug => {
    const isHome = currentPath.slice(0, -1) === localeSlug && slug === 'home'
    return currentPath === getLocalizedSlug(slug) || isHome
  }

  const getLocalizedFields = arr =>
    arr?.filter(({ node_locale }) => node_locale === locale)?.[0]

  // get name of current locale by default
  const getCountryName = name => {
    if (!name) {
      name = localeNodes?.find(({ code }) => code === locale)?.name
    }

    const countryName = name?.match(/\((.+?)\)/)?.[1]

    switch (countryName) {
      case 'United Kingdom':
        return 'UK/Ireland'
      case 'Spain':
        return 'España'
      case 'Italy':
        return 'Italia'
      case 'Germany':
        return 'Deutschland'
      default:
        return countryName
    }
  }

  const getCountryCode = locale => {
    const countryCode = locale.split('-')[1].toLowerCase()
    return countryCode === 'gb' ? 'uk' : countryCode
  }

  return (
    <LocaleContext.Provider
      value={{
        locale,
        defaultLocale: defaultLocale?.code,
        locales: localeNodes,
        localeSlug: ogLocaleSlug,
        lowercaseLocaleSlug: localeSlug,
        getLocalizedSlug,
        getLocalizedFields,
        getCountryName,
        getCountryCode,
        currentSlug,
        currentQuery,
        currentPath,
        isActiveSlug,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}
