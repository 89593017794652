import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
const accessToken = process.env.GATSBY_CONTENTFUL_PREVIEW_API

const accessPath = `access_token=${accessToken}`
const basePath = `/environments/${environment}`
const entriesPath = `${basePath}/entries`

export const GET_ENTRY_BY_ID = gql`
  query getEntryById($id: String!, $locale: String!) {
    entry(id: $id , locale: $locale) @rest(type: "Entry", path: "${entriesPath}?${accessPath}&sys.id[in]={args.id}&include=4&locale={args.locale}") {
      items
      includes
    }
  }
`

// apollo gets buggy if you don't add ids to subqueries
const usePreviewQuery = ({
  query,
  id,
  skipBool = false,
  ids,
  locale = 'en-US',
  limit = '100',
}) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    query,
    ids
      ? {
          variables: { id, ids, locale, limit },
          skip: skipBool,
          notifyOnNetworkStatusChange: true,
          errorPolicy: 'all',
        }
      : {
          variables: { id, locale },
          skip: skipBool,
          notifyOnNetworkStatusChange: true,
          errorPolicy: 'all',
        }
  )

  return { loading, error, data, refetch, networkStatus }
}

export default usePreviewQuery
