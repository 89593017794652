import Default from './Default'

const themeType = process.env.GATSBY_THEME

const getTheme = () => {
  switch (themeType) {
    case 'default':
    default:
      return Default
  }
}

const theme = getTheme()

export default theme
