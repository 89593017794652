import React, { useContext } from 'react'
import { PreviewContext } from '../context'

export const usePreview = () => {
  return useContext(PreviewContext)
}

export const PreviewProvider = ({ locale, children }) => {
  return (
    <PreviewContext.Provider value={{ locale }}>
      {children}
    </PreviewContext.Provider>
  )
}
