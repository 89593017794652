// Feature flags for both build time and run time
// Remove flags when they are released as official features

const featureFlags = {
    MOTION_BG: 'MOTION_BG',
    IS_LOCALIZED: 'IS_LOCALIZED'
  }
  
  const isMotionBg = () => {
    return process.env.GATSBY_ENABLE_MOTION_BG === 'true'
  }
  
  const isLocalized = () => {
    return process.env.GATSBY_ENABLE_LOCALIZATION === 'true'
  }

  const isFeatureEnabled = (feature, options) => {
    switch (feature) {
      case featureFlags.MOTION_BG:
        return isMotionBg()
      case featureFlags.IS_LOCALIZED:
        return isLocalized()
      default:
        return false
    }
  }
  
  // Need to use this form for node (gatsby createPages)
  module.exports = { isFeatureEnabled, featureFlags }
  