import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles'
import theme from '@themes'
import { LocaleProvider, PreviewProvider } from '@hooks'
import SEO from '@components/SEO'
import Container from '@components/Container'
import { ParallaxBackground } from '@components/ParallaxBackground'

const Layout = ({ preview, children, locale = 'en-US', seo }) => {

  // make sure doesnt interfere with gatsby TL wrapper
  return (
    <ThemeProvider theme={theme}>
      <PreviewProvider value={preview}>
        <LocaleProvider locale={locale}>
          {seo && <SEO lang={locale} {...seo} />}
          <GlobalStyle />
          <ParallaxBackground />
          <Container>{children}</Container>
        </LocaleProvider>
      </PreviewProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  locale: PropTypes.string,
}

export default Layout
